import { Fragment, useState, FC, memo } from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro';

// Components
import { Button } from "components";
import AddFolder from './Modal/AddFolder';

type Props = {
  directoryId?: number
  onSuccess: (params: BrowseParams) => Promise<void>
}

const Add: FC<Props> = ({directoryId, onSuccess}) => {
  const [isOpen, setOpen] = useState(false);

  const toggle = () => setOpen((prev) => !prev);

  const Folder = {
    title: (
      <div className="flex gap-x-3 items-center">
        <FontAwesomeIcon icon={icon({name: 'folder'})} />
        <span>Tambah Folder</span>
      </div>
    ),
    onItemClick: toggle,
  };

  const BankSoal = {
    linkTo: `/bank-soal/tambah-baru/${directoryId}`,
    state: { directory_id: directoryId },
    title: (
      <div className="flex gap-x-3 items-center">
        <FontAwesomeIcon icon={icon({name: 'file-lines'})} />
        <span>Tambah Soal</span>
      </div>
    ),
  };

  return (
    <Fragment>
      <Button.Create
        isDropdown
        items={directoryId ? [Folder, BankSoal] : [Folder]}
      />

      <AddFolder
        show={isOpen}
        directoryId={directoryId}
        onClose={toggle}
        onSuccess={onSuccess}
      />
    </Fragment>
  );
};

export default memo(Add);
