import {memo, FC, useState, useEffect} from 'react';
import Modal from 'components/Modal';
import { services } from "services";

import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import useSoal from 'services/useSoal';


type Props = {
  show: boolean
  onClose: () => void
  onSuccess?: () => Promise<void>
  data?: BrowseItem | null
}

const DeleteSoal: FC<Props> = (props) => {
  const {show, onClose, onSuccess, data} = props;
  const {deleteSoal} = useSoal();
  const [currentStatus, setCurrentStatus] = useState<'init'|'failed'|'deleted'>('init')

  async function deleteDirectory(directory_id: number) {
    services
      .delete(`/admin/directory/${directory_id}`)
      .then(() => {
        setCurrentStatus('deleted')
      })
      .catch(() => {
        setCurrentStatus('failed')
      });
  }

  useEffect(() => {
    setCurrentStatus('init')
  }, [data, show])

  const handleDelete = async () => {
    if (data) {
      if (data.type === 'directory') {
        await deleteDirectory(data.id)
      } else if (data.type === 'question') {
        const response = await deleteSoal(data.id)
        if (response === 'success') {
          setCurrentStatus('deleted')
          onSuccess?.()
        }else{
          setCurrentStatus('failed')
        }
      }
    }
  };

  return (
    <Modal
      title={''}
      show={show}
      onClose={onClose}
      customFooter={(<></>)}
    >
      <div className="px-6 py-4 flex flex-col items-center">
        <DeleteModalItem
          name={data?.name ?? ''}
          onClose={onClose}
          handleDelete={handleDelete}
          status={currentStatus}
        />
      </div>
    </Modal>
  )
}

const DeleteModalItem = ({status, name, onClose, handleDelete}:{status: 'init'|'failed'|'deleted', name: string, onClose: () => void, handleDelete: () => void}) =>{
  switch (status){
    case "failed":
      return(
        <>
          <FontAwesomeIcon
            icon={icon({name: 'times-circle'})}
            size={'3x'}
            color={'#F04438'}
          />
          <div className="px-4 pt-6 pb-8 space-y-2">
            <p className="text-center text-sm	text-[#666666]">
              {`${name} tidak bisa dihapus karena masih di alokasikan ke dalam silabus / program, silahkan sesuaikan terlebih dahulu sebelum menghapus`}
            </p>
          </div>
          <div className="flex gap-x-2">
            <button
              onClick={onClose}
              className="px-4 py-[0.625rem] rounded-xl bg-primary"
            >
              Tutup
            </button>
          </div>
        </>
      )
    case "deleted":
      return(
        <>
          <FontAwesomeIcon
            icon={icon({name: 'trash-alt'})}
            size={'3x'}
            color={'#F04438'}
          />
          <div className="px-4 pt-6 pb-8 space-y-2">
            <p className="text-center font-bold text-base	">
              {`${name} berhasil dihapus!`}
            </p>
            <p className="text-center text-sm	text-[#666666]">
              Folder/soal yang telah dihapus tidak dapat dipulihkan kembali.
            </p>
          </div>
          <div className="flex gap-x-2">
            <button
              onClick={onClose}
              className="px-4 py-[0.625rem] rounded-xl bg-primary"
            >
              Tutup
            </button>
          </div>
        </>
      )
    default:
      return(
        <>
          <FontAwesomeIcon
            icon={icon({name: 'info-circle'})}
            size={'3x'}
            color={'#F04438'}
          />
          <div className="px-4 pt-6 pb-8 space-y-2">
            <p className="text-center font-bold text-base	">
              Anda yakin?
            </p>
            <p className="text-center text-sm	text-[#666666]">
              {`Anda akan menghapus soal ${name}. Soal tidak bisa di hapus bila sudah di alokasikan ke dalam silabus atau program. Apakah anda yakin?`}
            </p>
          </div>
          <div className="flex gap-x-2">
            <button
              onClick={onClose}
              className="px-4 py-[0.625rem] rounded-xl border border-primary"
            >
              Batal
            </button>
            <button
              onClick={handleDelete}
              className="px-4 py-[0.625rem] rounded-xl bg-primary"
            >
              Hapus
            </button>
          </div>
        </>
      )
  }
}

export default memo(DeleteSoal);
