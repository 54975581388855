import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Disclosure } from "@headlessui/react";
import {  Spinner } from "components";
import { FC, memo, useEffect, useState } from "react";
import useBrowse from "services/useBrowse";

type Props = {
  directoryId: number;
  indent: number;
  initialData?: BrowseItem[];
  onSelectDirectory: (item: BrowseItem) => void;
  currentDirectory?: BrowseItem;
  selectedDirectory: BrowseItem | null;
};

const Folders: FC<Props> = (props) => {
  const {
    directoryId,
    indent,
    initialData,
    onSelectDirectory,
    currentDirectory,
    selectedDirectory
  } = props;
  const { getList } = useBrowse();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<BrowseItem[]>([]);


  const handleSelectedDestination = (item: BrowseItem) => {
    onSelectDirectory(item);

  };

  useEffect(() => {
    if (!initialData?.length && directoryId) {
      setLoading(true);
      getList({ directory_id: Number(directoryId), per_page: 50 })
        .then((response) => setData(response?.data || []))
        .catch((err) => console.log("Err", err))
        .finally(() => setLoading(false));
    }
  }, [directoryId, getList, initialData]);

  useEffect(() => {
    setData(initialData || []);
  }, [initialData]);


  return (
    <>
      {loading ? (
        <Spinner />
      ) : data.length === 0 ? (
        <div className="text-center">No data available</div>
      ) : (
        data?.map((item) => {
          let paddingLeft = 32 * (indent - 1);
          paddingLeft = paddingLeft ? paddingLeft : 4;

          if (item.type === "directory") {
            return (
              <Disclosure key={`directory-${item.id}`}>
                {({ open }) => {
                  const itemIcon = open
                    ? icon({ name: "chevron-down" })
                    : icon({ name: "chevron-right" });

                  return (
                    <>
                    
                      <Disclosure.Button
                        className={
                          "flex w-full items-center gap-x-4 pr-4 py-2 font-medium hover:bg-yellow-100 border-b"
                        }
                        style={{ paddingLeft }}
                      >
                        <input
                          readOnly
                          type="radio"
                          className="radio checked:bg-primary checked:border-none text-end mt-2 mb-2 mr-2"
                          id={`question-${item.id}`}
                          name="folder"
                          checked={selectedDirectory !== null && item.id === selectedDirectory.id}
                          onClick={() => {
                            handleSelectedDestination(item)
                          }}
                        />
                        <FontAwesomeIcon icon={itemIcon} />

                        <section className="flex items-center gap-x-3 text-left">
                          <FontAwesomeIcon icon={icon({ name: "folder" })} />
                          <span>{item.name}</span>
                        </section>
                      </Disclosure.Button>

                      {open && (
                        <Disclosure.Panel>
                          <Folders
                            directoryId={item.id}
                            indent={indent + 1}
                            onSelectDirectory={handleSelectedDestination}
                            selectedDirectory={selectedDirectory}
                            currentDirectory={currentDirectory}
                          />
                        </Disclosure.Panel>
                      )}
                    </>
                    
                  );
                }}
              </Disclosure>
            );
          }
        })
      )}
    </>
  );
};

export default memo(Folders);
