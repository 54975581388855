import { Input, Label, Spinner } from "components";
import { FC, memo, useCallback, useEffect, useState } from "react";
import useSchool from "services/useSchool";
import { Combobox } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Controller, useFormContext } from "react-hook-form";
import { useDebounce } from "utilities";
import { InView } from "react-intersection-observer";

type Props = {
  isReview?: boolean;
};

type Params = {
  name: string;
  page: number;
};

const defaultPagination: PaginationResponse = {
  current_page: 1,
  per_page: 10,
  last_page: 1,
  from: 1,
  to: 1,
  total: 1,
};

const SchoolInfo: FC<Props> = ({ isReview }) => {
  const { getList, getClassBySchool } = useSchool();

  const { setValue, watch, control } = useFormContext<StudentPayload>();
  const { schools } = watch();

  const [schoolList, setSchoolList] = useState<Common[]>([]);
  const [classList, setClassList] = useState<Common[]>([]);
  const [selectedSchool, setSelectedSchool] = useState<Common | null>(null);
  const [selectedClass, setSelectedClass] = useState<Common | null>(null);
  const [pagination, setPagination] = useState(defaultPagination);
  const [search, setSearch] = useState("");

  const debounceSearch = useDebounce(search);

  const getSchool = useCallback(
    async (params?: Params) => {
      const { page = 1, name = "" } = params ?? {};
      const response = await getList({
        name,
        page,
      });
      const { data, ...restResponse } = response;

      let list = [];
      if (page === 1) {
        list = data;
      } else {
        list = [...schoolList, ...data];
      }
      list.push({
        id: 0,
        name: "lainnya",
      })

      setSchoolList(list);
      setPagination(restResponse);
    },
    // eslint-disable-next-line
    [schoolList]
  );

  const getClass = useCallback(async (id: string) => {
    const response = await getClassBySchool(id);
    setClassList(response);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setSelectedSchool({
      id: Number(schools?.id),
      name: schools?.name ?? "",
    });
    setSelectedClass({
      id: Number(schools?.school_class_id),
      name: schools?.school_class_name ?? "",
    });
  }, [schools]);

  useEffect(() => {
    if (!isReview) {
      if (debounceSearch.length >= 3) {
        getSchool({
          page: 1,
          name: debounceSearch,
        });
      }

      if (!debounceSearch.length) {
        getSchool();
      }
    }
    // eslint-disable-next-line
  }, [isReview, debounceSearch]);

  const loadMore = () => {
    getSchool({
      name: search,
      page: pagination?.current_page + 1,
    });
  };

  const handleSchool = (value: Common) => {
    const isOther = value.name.toLowerCase() === "lainnya";
    setSearch("");
    setSelectedClass(null);
    setSelectedSchool(value);
    setValue("schools.id", String(value.id));
    setValue("schools.name", isOther ? "" : value.name);
    setValue("schools.school_class_id", "");
    setValue("schools.school_class_name", "");
    setValue("schools.isOther", isOther);
    getClass(String(value.id));
  };

  const handleClass = (value: Common) => {
    setSelectedClass(value);
    setValue("schools.school_class_id", String(value.id));
    setValue("schools.school_class_name", value.name);
  };

  return (
    <div className="bg-white rounded-xl">
      <p className="font-bold p-5 border-b">Informasi sekolah</p>
      <div className="p-5 space-y-5">
        <div>
          <Label>Nama sekolah</Label>
          <Combobox
            disabled={isReview}
            value={selectedSchool}
            onChange={handleSchool}
          >
            <div className="relative">
              <Combobox.Button
                as="div"
                className={`flex border rounded-lg px-3.5 py-2.5 items-center gap-x-2 ${
                  isReview ? "bg-gray-50" : "bg-white"
                }`}
              >
                <Combobox.Input
                  className="w-full"
                  displayValue={() => selectedSchool?.name || search}
                  placeholder="Masukkan nama sekolah"
                  onChange={(e) => setSearch(e.target.value)}
                />
                <FontAwesomeIcon icon={icon({ name: "chevron-down" })} />
              </Combobox.Button>
              <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 z-10">
                {schoolList.length === 0 ? (
                  <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                    Data tidak ditemukan.
                  </div>
                ) : (
                  schoolList.map((school, index) => (
                    <Combobox.Option
                      key={`${school.id}-${index}`}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 text-gray-900 ${
                          active ? "bg-primary" : ""
                        }`
                      }
                      value={school}
                    >
                      {() => {
                        const selected = selectedSchool?.id === school.id;

                        return (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? "font-medium" : "font-normal"
                              }`}
                            >
                              {school.name}
                            </span>
                            {selected ? (
                              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                <FontAwesomeIcon
                                  icon={icon({ name: "check" })}
                                />
                              </span>
                            ) : null}
                          </>
                        );
                      }}
                    </Combobox.Option>
                  ))
                )}
                {pagination.current_page < pagination.last_page && (
                  <InView
                    as="div"
                    onChange={(inView) => {
                      if (inView) {
                        loadMore();
                      }
                    }}
                  >
                    <Spinner />
                  </InView>
                )}
              </Combobox.Options>
            </div>
          </Combobox>
        </div>
        {schools?.isOther ? (
          <>
            <Controller
              control={control}
              name="schools.name"
              render={({ field }) => (
                <Input
                  label="Nama sekolah"
                  placeholder="Masukkan nama sekolah"
                  disabled={isReview}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <Controller
              control={control}
              name="schools.nis"
              render={({ field }) => (
                <Input
                  label="NIS"
                  placeholder="Masukkan NIS"
                  disabled={isReview}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <Controller
              control={control}
              name="schools.school_class_name"
              render={({ field }) => (
                <Input
                  label="Kelas"
                  placeholder="Masukkan kelas"
                  disabled={isReview}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </>
        ) : (
          <div>
            <Label>Kelas</Label>
            <Combobox
              disabled={!selectedSchool || isReview}
              value={selectedClass}
              onChange={handleClass}
            >
              <div className="relative">
                <Combobox.Button
                  as="div"
                  className={`flex border rounded-lg px-3.5 py-2.5 items-center gap-x-2 ${
                    isReview ? "bg-gray-50" : "bg-white"
                  }`}
                >
                  <span className="w-full">
                    {selectedClass?.name || "Pilih kelas"}
                  </span>
                  <FontAwesomeIcon icon={icon({ name: "chevron-down" })} />
                </Combobox.Button>
                <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 z-10">
                  {classList.map((val, index) => (
                    <Combobox.Option
                      key={`${val.id}-${index}`}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 text-gray-900 ${
                          active ? "bg-primary" : ""
                        }`
                      }
                      value={val}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {val.name}
                          </span>
                          {selected ? (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                              <FontAwesomeIcon icon={icon({ name: "check" })} />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Combobox.Option>
                  ))}
                </Combobox.Options>
              </div>
            </Combobox>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(SchoolInfo);
