import { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Button } from "components";
import Breadcrumbs from "components/Breadcrumbs";
import {
  validateEmail,
  validateLength,
  validateLowercase,
  validateNumber,
  validateSpecialChar,
  validateUppercase,
} from "utilities";
import StudentInfo from "./fragment/Add/StaffInfo";
import SchoolInfo from "./fragment/Add/SchoolInfo";
import Review from "./fragment/Add/Review";
import SuccessAdd from "./fragment/Modal/SuccessAdd";
import useSchoolStaff from "services/useSchoolStaff";

const STEPS = [
  {
    label: "Informasi staff",
    component: <StudentInfo />,
  },
  {
    label: "Informasi sekolah",
    component: <SchoolInfo />,
  },
  {
    label: "Review",
    component: <Review />,
  },
];

const Add = () => {
  const { add } = useSchoolStaff();
  const navigate = useNavigate();

  const [showSuccess, setShowSuccess] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  const methods = useForm<SchoolStaffPayload>({
    defaultValues: {
      name: "",
      email: "",
      password: "",

      phone_code: "+62",
      phone_number: "",
      schools: {
        id: "",
        isOther: false,
        name: "",
        nis: "",
        school_class_id: "",
        school_class_name: "",
      },
    },
  });
  const { watch, handleSubmit } = methods;
  const {
    name,
    email,
    phone_number,
    password,
    schools,
  } = watch();
  const {
    id: schoolId,
    name: schoolName,
    nis,
    isOther
  } = schools ?? {};

  const isReview = useMemo(() => {
    return currentStep === STEPS.length;
  }, [currentStep]);

  const vaildateSchoolStaffInfo = useMemo(() => {
    const validEmail = validateEmail(email);
    const isLowercase = validateLowercase(String(password));
    const isUppercase = validateUppercase(String(password));
    const isNumber = validateNumber(String(password));
    const isLength = validateLength(String(password), 8);
    const isSpecialChar = validateSpecialChar(String(password));
    const validPassword =
      !password ||
      (isLowercase && isUppercase && isNumber && isLength && isSpecialChar);

    return Boolean(
      name &&
        validEmail &&
        phone_number &&
        validPassword
    );
  }, [name, email, phone_number, password]);

  const validateSchoolInfo = useMemo(() => {
    if (isOther) {
      return Boolean(schoolName && nis );
    }
    return Boolean(schoolId);
  }, [schoolId, schoolName, nis, isOther]);

  const canContinue = useMemo(() => {
    switch (currentStep) {
      case 1:
        return vaildateSchoolStaffInfo;
      case 2:
        return validateSchoolInfo;
      default:
        return true;
    }
  }, [currentStep, vaildateSchoolStaffInfo, validateSchoolInfo]);

  const onCloseModal = () => {
    setShowSuccess(false);
    navigate("/school-staf", {
      replace: true,
    });
  };

  const handleCancel = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleNext = (data: SchoolStaffPayload) => {
    if (isReview) {
      onSubmit(data);
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const onSubmit = (data: SchoolStaffPayload) => {
    toast.promise(add(data), {
      loading: "Menambakan staff sekolah...",
      success: () => {
        setShowSuccess(true);
        return "";
      },
      error: (err) => {
        return err;
      },
    });
  };

  const handleCrumb = (item: Crumb) => {
    navigate("/school-staf", {
      replace: true,
    });
  };

  return (
    <div className="space-y-5">
      <Breadcrumbs crumbs={["Staff Sekolah", "Tambah Staff Sekolah"]} onClick={handleCrumb} />
      <div className="grid grid-cols-[23rem,1fr] gap-x-8">
        <div>
          <h3 className="font-bold">Tambah staff sekolah</h3>
          <p className="text-gray-500 mt-1.5 mb-8">
            Masukkan semua informasi yang dibutuhkan untuk menambah staff sekolah baru
            ke platform Guruku.
          </p>
          <div className="flex flex-col gap-y-1">
            {STEPS.map((step) => step.label).map((val, index) => {
              const isLast = STEPS.length - 1 === index;
              const isActive = currentStep - 1 === index;
              const isDone = currentStep - 1 > index;

              return (
                <div key={val} className="flex gap-x-4">
                  <div className="flex flex-col w-8">
                    {isDone ? (
                      <div className="bg-[#E8D000] w-8 h-8 rounded-full grid place-items-center">
                        <FontAwesomeIcon
                          icon={icon({ name: "check" })}
                          size="lg"
                          color="white"
                        />
                      </div>
                    ) : (
                      <div
                        className={`grid place-items-center w-8 h-8 rounded-full ${
                          isActive
                            ? "bg-[#E8D000] outline-4 outline outline-gray-200"
                            : "bg-white outline-2 outline outline-gray-200"
                        }`}
                      >
                        <div
                          className={`w-2 h-2 rounded-full ${
                            isActive ? "bg-white" : "bg-gray-400"
                          }`}
                        />
                      </div>
                    )}
                    {!isLast && (
                      <div
                        className={`w-0.5 h-5 mt-1 self-center ${
                          isDone ? "bg-[#E8D000]" : "bg-gray-200"
                        }`}
                      />
                    )}
                  </div>
                  <p
                    className={`mt-1 font-semibold ${
                      isActive ? "text-[#B5A300]" : ""
                    }`}
                  >
                    {val}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
        <FormProvider {...methods}>
          {STEPS[currentStep - 1].component}
        </FormProvider>
      </div>
      <div className="space-x-3 float-right pb-5">
        <Button.Cancel onButtonClick={handleCancel} />
        <Button
          color="primary"
          onButtonClick={handleSubmit((data) => handleNext(data))}
          isDisabled={!canContinue}
        >
          {isReview ? "Simpan" : "Lanjutkan"}
        </Button>
      </div>
      <SuccessAdd show={showSuccess} onClose={onCloseModal} />
    </div>
  );
};

export default Add;
