import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Badge } from "components";

interface Module {
  type: string;
  id: number;
  name: string;
  updated_at: string;
  grade_id: string | null;
  difficulty: number;
  grade_name: string;
  n_essay: number;
  n_multiple_choice: number;
  totalEssay: number;
  totalMultiple: number;
}

interface MeetingQuestionListProps {
  modules: Module[];
  onDelete?: (index: number) => void;
}

const MeetingQuestionList: React.FC<MeetingQuestionListProps> = ({ modules, onDelete }) => {

  return (
    <>
      {modules.map((question, index) => (
        <div key={`question-${question.id}`} className='flex w-full justify-between items-center mt-5'>
          <div className='flex items-center gap-x-2'>
            <FontAwesomeIcon icon={icon({name: 'file-lines'})} />
            <p>{question.name}</p>
          </div>
          <div className='flex items-center gap-x-4'>
            <Badge color='blue'>
              {`${question.n_multiple_choice} PG`}
            </Badge>
            <Badge color='success'>
              {`${question.n_essay} essay`}
            </Badge>
            <Badge color='gray'>
              {`1`}
            </Badge>
            {onDelete &&
              <FontAwesomeIcon
                icon={icon({name: 'trash-can'})}
                className='cursor-pointer'
                onClick={() => onDelete(index)}
              />
            }
          </div>
        </div>
      ))}
    </>
  );
};

export default MeetingQuestionList;
