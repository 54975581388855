import { API_URL } from "constant";
import { services } from "./services";
import { AxiosError } from "axios";

type Params = {
  per_page: number;
  search: string;
  sort_by: "name" | "phone" | "email" | "grade";
  order: "asc" | "desc";
  page: number;
};

type List = PaginationResponse & {
  data: SchoolStaffData[];
};

type Errors = {
  errors: {
    [key: string]: [string];
  };
  message: string;
};

const useSchoolStaff = () => {
  const getList = async (params?: Params): Promise<List> => {
    try {
      const {
        per_page = 10,
        search = "",
        sort_by = "name",
        order = "asc",
        page = 1,
      } = params ?? {};
      const resp = await services.get(API_URL.schoolStaff, {
        params: {
          per_page,
          sort_by,
          search,
          order,
          page,
        },
      });

      return resp.data?.data;
    } catch (error) {
      return {
        current_page: 1,
        data: [],
        from: 1,
        last_page: 1,
        per_page: params?.per_page ?? 10,
        to: 1,
        total: 0,
      };
    }
  };

  const add = async (params: SchoolStaffPayload) => {
    try {
      const { schools, ...restParams } = params;
      let payload: SchoolStaffPayload = {
        ...restParams,
      };

      if (schools?.isOther) {
        payload.schools = {
          nis: schools.nis,
          name: schools.name,
          school_class_name: schools.school_class_name,
        };
      } else {
        payload.schools = {
          id: schools?.id,
          school_class_id: schools?.school_class_id,
        };
      }

      await services.post(API_URL.schoolStaff, payload);
    } catch (error) {
      const err = error as AxiosError;
      const { errors, message } =
        (err.response?.data as Errors) || ({} as Errors);
      const errMsg = Object.values(errors)[0]?.[0] || message;
      throw errMsg;
    }
  };

  const edit = async (id: string, params: SchoolStaffPayload) => {
    try {
      const { password, ...restParams } = params;
      let payload: Partial<SchoolStaffPayload> = restParams;
      if(params.schools && params.schools.isOther) {
        payload = {
          ...restParams,
          schools: {
            nis: params.schools.nis,
            name: params.schools.name,
          }
        }
      } else {
        payload = {
          ...restParams,
          schools: {
            id: params.schools?.id,
          }
        }
      }


      await services.put(`${API_URL.schoolStaff}/${id}`, payload);
    } catch (error) {
      const err = error as AxiosError;
      const { errors, message } =
        (err.response?.data as Errors) || ({} as Errors);
      const errMsg = Object.values(errors)[0]?.[0] || message;
      throw errMsg;
    }
  };

  const getDetail = async (id: string): Promise<SchoolStaffData> => {
    try {
      const response = await services.get(`${API_URL.schoolStaff}/${id}`);

      return response.data?.data;
    } catch (error) {
      throw error;
    }
  };

  const deleteSchoolStaff = async (id: string) => {
    try {
      await services.delete(`${API_URL.schoolStaff}/${id}`);
    } catch (error) {
      throw error;
    }
  };

  return {
    getList,
    add,
    deleteSchoolStaff,
    getDetail,
    edit,
  };
};

export default useSchoolStaff;
