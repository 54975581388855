import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Table from "components/Table";
import { FC, memo } from "react";
import MeetingModuleList from "./ListModule";

type Props = {
  isEdit?: boolean;
  data: LiveclassPayload["meetings"];
  removeQuestion?: (item: LiveclassPayload["meetings"][0]) => void;
  onChange?: (value: number, index: number) => void;
};

const columns = [
  {
    title: "Pertemuan",
    isSort: true,
    key: "no",
  },
  {
    title: "Materi",
    isSort: true,
    key: "materi",
  },
  {
    title: "Soal",
    isSort: true,
  },
  {
    title: "Jadwal",
    isSort: true,
    key: "updated_at",
  },
  {
    title: "Durasi",
    isSort: true,
  },
  {
    title: "",
  },
];

const ListMeetings: FC<Props> = (props) => {
  const { isEdit = false, data, removeQuestion, onChange } = props;

  return (
    <Table
      data={data}
      columns={isEdit ? columns : [...columns.slice(0, 5)]}
      showFooter={false}
      handleFirstColumn={"max-w-fit"}
    >
      {data.map((val, index) => (
        <tr key={index} className="border-b">
          <td className="px-4 py-2.5 !w-[20px]">
            {index+1}
          </td>
          <td className="px-4 py-2.5">
            <MeetingModuleList
              modules={val.materi}
              // onDelete={(moduleIndex) => handleRemoveModule(index, moduleIndex)}
            />
          </td>
          <td className="px-4 py-2.5">
            {val.question.map((item: any) => 
               <div className='flex items-center gap-x-2'>
               <FontAwesomeIcon icon={icon({name: 'file-lines'})} />
               <p>{item.name}</p>
             </div>)}
          </td>
          <td className="px-4 py-2.5">
            {val.date_start}
          </td>
          <td className="px-4 py-2.5">
          {val.durasi.jam} jam {val.durasi.menit} menit
          </td>
          {isEdit && (
            <td className="px-4 py-2.5">
              <FontAwesomeIcon
                icon={icon({ name: "trash-alt" })}
                className="cursor-pointer"
                onClick={() => removeQuestion?.(val)}
              />
            </td>
          )}
        </tr>
      ))}
    </Table>
  );
};

export default memo(ListMeetings);
