import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, memo, useEffect, useState } from "react";
import { currencyConverter, getFormatDate } from "utilities";
import Chart from "react-apexcharts";

const DataRecap = [
  {
    id: "learn",
    name: "E-learning",
    value: 0,
  },
  {
    id: "class",
    name: "Live class",
    value: 0,
  },
  {
    id: "tryout",
    name: "Tryout",
    value: 0,
  },
  {
    id: "ask_homework",
    name: "Tanya PR",
    value: 0,
  },
];

const SeriesData = [
  {
    name: "Keseluruhan",
    data: [0],
    color: "#2E93FA",
  },
  {
    name: "E-learning",
    data: [0],
    color: "#00E396",
  },
  {
    name: "Live class",
    data: [0],
    color: "#9E77ED",
  },
  {
    name: "Tryout",
    data: [0],
    color: "#E3BF00",
  },
  {
    name: "Tanya PR",
    data: [0],
    color: "#F44747",
  },
];

type Props = {
  recapStudent: RecapStudent | null;
  buyer: GraphBuyer | null;
};

const Summary: FC<Props> = ({ buyer, recapStudent }) => {
  const [recap, setRecap] = useState(DataRecap);
  const [categories, setCategories] = useState([""]);
  const [series, setSeries] = useState(SeriesData);

  useEffect(() => {
    const tempData = DataRecap.map((val) => {
      if (val.id === "ask_homework") {
        val.value = recapStudent?.ask_homework ?? 0;
      } else {
        const recapId = val.id as keyof RecapStudent["program"];
        const programValue = recapStudent?.program[recapId] ?? 0;
        val.value = programValue;
      }

      return val;
    });

    setRecap(tempData);
  }, [recapStudent]);

  useEffect(() => {
    if (buyer) {
      const listDate = Object.keys(buyer);
      const listValue = Object.values(buyer);

      const overall = listValue.map((val) => val.all);
      const learn = listValue.map((val) => val.learn);
      const classData = listValue.map((val) => val.class);
      const tryout = listValue.map((val) => val.tryout);
      const askHomework = listValue.map((val) => val.ask_homework);
      const listData = [overall, learn, classData, tryout, askHomework];

      setCategories(listDate);
      setSeries((oldSeries) =>
        oldSeries.map((val, index) => ({
          name: val.name,
          color: val.color,
          data: listData[index],
        }))
      );
    }
  }, [buyer]);

  return (
    <div className="bg-white rounded-xl px-5 py-4 grid grid-cols-[23rem,1fr] gap-x-10">
      <div className="space-y-5">
        <div className="bg-yellow-100 rounded-xl px-5 py-4 space-y-2">
          <div className="flex items-center gap-x-2">
            <FontAwesomeIcon
              icon={icon({ name: "user-group" })}
              className="bg-primary rounded-full p-1.5 w-3.5 h-3.5"
            />
            <span className="text-sm font-medium">Total siswa yang memiliki produk</span>
          </div>
          <span className="font-bold text-5xl">
            {currencyConverter(recapStudent?.count_all ?? 0, true)}
          </span>
        </div>
        <div className="grid grid-cols-2 gap-4">
          {recap.map((val) => (
            <div
              key={val.id}
              className="bg-gray-50 rounded-xl px-5 py-4 space-y-1"
            >
              <p className="font-medium text-sm">{val.name}</p>
              <span className="font-bold text-2xl">
                {currencyConverter(val.value, true)}
              </span>
            </div>
          ))}
        </div>
      </div>
      {/* chart */}
      <div className="w-full h-96">
        <Chart
          height={"100%"}
          width={"100%"}
          options={{
            chart: {
              toolbar: {
                show: false,
              },
            },
            dataLabels: {
              enabled: false,
            },
            legend: {
              position: "top",
            },
            stroke: {
              curve: "smooth",
            },
            xaxis: {
              categories: categories,
              labels: {
                formatter: function (val) {
                  return `${getFormatDate(val, "DD/MM")}`;
                },
              },
            },
          }}
          series={series}
          type="area"
        />
      </div>
    </div>
  );
};

export default memo(Summary);
