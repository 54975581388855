// import Image from "next/image";
import { Icon } from "components";
import React from "react";
import { useLocation } from "react-router-dom";
import { services } from "services";

type Props = {
  onChange: (value: File | null, url: string, asset_id: number | null) => void;
  id: string;
  subject_id: string;
  grade_id: string;
};

export default function AddImageAnswer({
  onChange,
  id,
  subject_id,
  grade_id,
}: Props) {
  const storage = "s3";

  return (
    <div className="flex">
      <label
        htmlFor={id}
        className="text-[#8C8500] font-medium gap-2 flex items-center rounded-xl cursor-pointer text-sm h-[40px] w-fit justify-center shrink-0"
      >
        <Icon.Image className=" w-5 h-5" />
        Tambah Gambar
      </label>
      <input
        onChange={(event) => {
          if (event.target.files?.length) {
            const url = URL.createObjectURL(event.target.files[0]);
            const file = event.target.files[0];
            const data = {
              grade_id,
              subject_id,
              storage: "s3",
              file: event.target.files[0],
              url,
            };
            const datas = new FormData();
            datas.append("grade_id", grade_id);
            datas.append("subject_id", subject_id);
            datas.append("storage", storage);
            datas.append("file", file);
            datas.append("url", url);

            services
              .post("asset/upload", datas)
              .then((resp) => {
                const asset_id = resp.data.data.id ? resp.data.data.id : null;
                onChange(file, url, asset_id);
              })
              .catch((error) => {
                console.log("error", error);
              });
          } else {
            onChange(null, "", null);
          }
        }}
        type="file"
        id={id}
        accept="image/*"
        className="invisible"
      />
    </div>
  );
}
