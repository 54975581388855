import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputNumber } from "components";
import ToggleSwitch from "components/ToggleSwitch";
import { FC, memo, useEffect, useMemo, useState } from "react";
import { currencyConverter } from "utilities";

type DurationDifficulty = keyof TryoutPayload["duration"] | "general";

type Props = {
  canEdit?: boolean;
  data: Partial<TryoutPayload>;
  onSwitch?: (isFlat: boolean) => void;
  onChange?: (difficulty: DurationDifficulty, value: number) => void;
  isEdit?: boolean;
  onSave?: (data: Partial<TryoutPayload>) => void;
};

const Duration: FC<Props> = (props) => {
  const {
    canEdit = false,
    data,
    onSwitch,
    onChange,
    isEdit: isEditMode = false,
    onSave,
  } = props;

  const [currentData, setData] = useState(data);
  const [isEdit, setIsEdit] = useState(isEditMode);

  const totalDuration = useMemo(() => {
    const { is_flat_duration, duration, question_package = [] } = currentData;
    const easyQuestion = question_package
      .filter((val) => val.difficulty === 1)
      .reduce((prev, current) => {
        return prev + current.n_question_value;
      }, 0);
    const mediumQuestion = question_package
      .filter((val) => val.difficulty === 2)
      .reduce((prev, current) => {
        return prev + current.n_question_value;
      }, 0);
    const hardQuestion = question_package
      .filter((val) => val.difficulty === 3)
      .reduce((prev, current) => {
        return prev + current.n_question_value;
      }, 0);
    if (is_flat_duration) {
      return duration?.easy || 0;
    } else {
      return (
        easyQuestion * (duration?.easy || 0) +
        mediumQuestion * (duration?.medium || 0) +
        hardQuestion * (duration?.hard || 0)
      );
    }
    // eslint-disable-next-line
  }, [currentData]);

  useEffect(() => {
    setData(data);
  }, [data]);

  const toggleEdit = () => setIsEdit(!isEdit);

  const handleCancel = () => {
    setData(data);
    toggleEdit();
  };

  const handleSave = () => {
    if (onSave) {
      onSave(currentData);
    }
    toggleEdit();
  };

  const handleSwitch = (value: boolean) => {
    if (onSwitch) {
      onSwitch(value);
    }
    setData({
      ...currentData,
      is_flat_duration: value,
      duration: {
        easy: 0,
        medium: 0,
        hard: 0,
      },
    });
  };

  const handleGeneral = (value: number) => {
    if (onChange) {
      onChange("general", value);
    }
    setData({
      ...currentData,
      duration: {
        easy: value,
        medium: value,
        hard: value,
      },
    });
  };

  const handleDuration = (difficulty: DurationDifficulty, value: number) => {
    const duration = {
      easy: currentData.duration?.easy || 0,
      medium: currentData.duration?.medium || 0,
      hard: currentData.duration?.hard || 0,
    };
    if (onChange) {
      onChange(difficulty, value);
    }
    setData({
      ...currentData,
      duration: {
        ...duration,
        [difficulty]: value,
      },
    });
  };

  return (
    <div className="flex-1 w-full bg-white rounded-xl">
      <div className="flex h-fit items-center justify-between px-5 py-4 border-b">
        <p className="font-bold">Pengaturan waktu</p>
        {canEdit &&
          (isEdit ? (
            <div className="flex items-center gap-x-2">
              <FontAwesomeIcon
                color="#F04438"
                icon={icon({ name: "close" })}
                className="cursor-pointer"
                onClick={handleCancel}
              />
              <FontAwesomeIcon
                color="#17B26A"
                icon={icon({ name: "floppy-disk", style: "regular" })}
                className="cursor-pointer"
                onClick={handleSave}
              />
            </div>
          ) : (
            <FontAwesomeIcon
              color="#B5A300"
              icon={icon({ name: "edit" })}
              className="cursor-pointer"
              onClick={toggleEdit}
            />
          ))}
      </div>
      <div className={`${isEdit ? "gap-y-5" : "gap-y-3"} p-5 grid`}>
        {isEdit ? (
          <>
            <div>
              <ToggleSwitch
                label="Waktu keseluruhan (menit)"
                isChecked={currentData.is_flat_duration}
                onChange={(val) => handleSwitch(val)}
              />
              <InputNumber
                label=""
                placeholder="Waktu keseluruhan"
                options={{
                  numeral: true,
                  delimiter: ".",
                  numeralDecimalMark: ",",
                }}
                disabled={!currentData.is_flat_duration}
                className="w-full mt-1.5 border border-gray-300 px-3 py-2 rounded-lg"
                value={
                  currentData.is_flat_duration
                    ? currentData.duration?.easy || ""
                    : ""
                }
                onChange={(e) => handleGeneral(Number(e.target.rawValue))}
              />
            </div>
            <div className="flex items-center gap-x-5">
              <InputNumber
                label="Soal mudah"
                placeholder="Soal mudah"
                options={{
                  numeral: true,
                  delimiter: ".",
                  numeralDecimalMark: ",",
                }}
                disabled={currentData.is_flat_duration}
                className="w-full border border-gray-300 px-3 py-2 rounded-lg"
                value={
                  currentData.is_flat_duration ? "" : currentData.duration?.easy
                }
                onChange={(e) =>
                  handleDuration("easy", Number(e.target.rawValue))
                }
              />
              <InputNumber
                label="Soal sedang"
                placeholder="Soal sedang"
                options={{
                  numeral: true,
                  delimiter: ".",
                  numeralDecimalMark: ",",
                }}
                disabled={currentData.is_flat_duration}
                className="w-full border border-gray-300 px-3 py-2 rounded-lg"
                value={
                  currentData.is_flat_duration
                    ? ""
                    : currentData.duration?.medium
                }
                onChange={(e) =>
                  handleDuration("medium", Number(e.target.rawValue))
                }
              />
              <InputNumber
                label="Soal sulit"
                placeholder="Soal sulit"
                options={{
                  numeral: true,
                  delimiter: ".",
                  numeralDecimalMark: ",",
                }}
                disabled={currentData.is_flat_duration}
                className="w-full border border-gray-300 px-3 py-2 rounded-lg"
                value={
                  currentData.is_flat_duration ? "" : currentData.duration?.hard
                }
                onChange={(e) =>
                  handleDuration("hard", Number(e.target.rawValue))
                }
              />
            </div>
          </>
        ) : (
          <>
            <div className="flex gap-x-2">
              <p className="font-medium text-gray-500 w-40">
                Waktu keseluruhan
              </p>
              <p className="font-bold text-lg">
                {currentData.duration?.easy && currentData.is_flat_duration
                  ? currencyConverter(currentData.duration?.easy, true)
                  : "-"}
              </p>
            </div>
            <div className="flex gap-x-2">
              <p className="font-medium text-gray-500 w-40">Soal mudah</p>
              <p className="font-bold text-lg">
                {currentData.duration?.easy && !currentData.is_flat_duration
                  ? currencyConverter(currentData.duration?.easy, true)
                  : "-"}
              </p>
            </div>
            <div className="flex gap-x-2">
              <p className="font-medium text-gray-500 w-40">Soal sedang</p>
              <p className="font-bold text-lg">
                {currentData.duration?.medium && !currentData.is_flat_duration
                  ? currencyConverter(currentData.duration?.medium, true)
                  : "-"}
              </p>
            </div>
            <div className="flex gap-x-2">
              <p className="font-medium text-gray-500 w-40">Soal sulit</p>
              <p className="font-bold text-lg">
                {currentData.duration?.hard && !currentData.is_flat_duration
                  ? currencyConverter(currentData.duration?.hard, true)
                  : "-"}
              </p>
            </div>
          </>
        )}
        <div className="grid gap-y-1.5">
          <p className="font-medium">Total waktu</p>
          <p className="font-bold text-xl">
            {currencyConverter(totalDuration, true)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default memo(Duration);
