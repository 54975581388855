import {memo, FC} from 'react';
import Modal from 'components/Modal';
import {getFormatDate} from 'utilities';

type Props = {
  show: boolean
  data: QuestionDetail | null
  onClose: () => void
}

const Soal: FC<Props> = ({show, data, onClose}) => {

  return (
    <Modal
      show={show}
      onClose={onClose}
      title='Info Soal'
    >
      <div className='mb-8'>
        <p className='font-bold text-sm'>Judul Soal</p>
        <p className='text-base text-gray-500'>{data?.name}</p>
      </div>
      <div className='mb-8'>
        <p className='font-bold text-sm'>Deskripsi Soal</p>
        <p className='text-base text-gray-500' dangerouslySetInnerHTML={{__html: data?.description || ''}} />
      </div>
      <div className='grid grid-flow-col mb-8'>
        <div>
          <p className='font-bold text-sm'>Tanggal dibuat</p>
          <p className='text-base text-gray-500'>
            {getFormatDate(String(data?.created_at), 'DD MMM YYYY')}
          </p>
        </div>
        <div>
          <p className='font-bold text-sm'>Tanggal diperbarui</p>
          <p className='text-base text-gray-500'>
            {getFormatDate(String(data?.updated_at), 'DD MMM YYYY')}
          </p>
        </div>
      </div>
    </Modal>
  )
}

export default memo(Soal);
