import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Listbox } from "@headlessui/react";
import {
  FC,
  memo,
  useState,
  useEffect,
  useCallback,
  useId,
  Fragment,
} from "react";
import useGrade from "services/useGrade";
import { useDebounce } from "utilities";

type Props = {
  onGetData: (params: ProgramParams) => Promise<void>;
};

type SORT = {
  id: number;
  name: string;
  sort_by: ProgramParams["sort_by"];
  order_by: ProgramParams["order_by"];
};

const SORT_LIST: SORT[] = [
  {
    id: 0,
    name: "Terbaru",
    sort_by: "created_at",
    order_by: "desc",
  },
  {
    id: 1,
    name: "Nama A-Z",
    sort_by: "name",
    order_by: "asc",
  },
  {
    id: 2,
    name: "Nama Z-A",
    sort_by: "name",
    order_by: "desc",
  },
  {
    id: 3,
    name: "Harga terendah",
    sort_by: "price",
    order_by: "asc",
  },
  {
    id: 4,
    name: "Harga tertinggi",
    sort_by: "price",
    order_by: "desc",
  },
  {
    id: 5,
    name: "Diskon terkecil",
    sort_by: "discount",
    order_by: "asc",
  },
  {
    id: 6,
    name: "Diskon tertinggi",
    sort_by: "discount",
    order_by: "desc",
  },
];

const STATUS = [
  {
    name: "Published",
    value: 0,
  },
  {
    name: "Draft",
    value: 1,
  },
  {
    name: "Semua",
    value: null,
  },
];

const defaultParams: ProgramParams = {
  page: 1,
  sort_by: "id",
  order_by: "desc",
  keyword: "",
  grade_id: 0,
};

const allGrade = {
  id: 0,
  name: "Semua strata",
};

type SelectType = {
  id: number;
  name: string;
  type: ProgramType | "";
};

const programType: SelectType[] = [
  {
    id: 0,
    name: "Semua",
    type: "",
  },
  {
    id: 1,
    name: "E-learning",
    type: "learn",
  },
  {
    id: 2,
    name: "Live class",
    type: "class",
  },
  {
    id: 3,
    name: "Tryout",
    type: "tryout",
  },
]
const Filter: FC<Props> = ({ onGetData }) => {
  const id = useId();
  const { getList } = useGrade();

  const [search, setSearch] = useState("");
  const [grades, setGrades] = useState<GradeItem[]>([]);
  const [selectedGrade, setSelectedGrade] = useState<GradeItem>(allGrade);
  const [sort, setSort] = useState(SORT_LIST[0]);
  const [status, setStatus] = useState<ProgramParams["status"]>(null);

  const debounceSearch = useDebounce(search);

  const getGrade = useCallback(async () => {
    const response = await getList();
    setGrades(response);
  }, [getList]);

  useEffect(() => {
    getGrade();
  }, [getGrade]);

  useEffect(() => {
    if (debounceSearch.length >= 3) {
      onGetData({
        keyword: debounceSearch,
        page: 1,
      });
    }

    if (!debounceSearch.length) {
      onGetData(defaultParams);
    }
    // eslint-disable-next-line
  }, [debounceSearch]);

  const clearSearch = async () => {
    setSearch("");
    await onGetData(defaultParams);
  };

  const handleGrade = async (value: typeof allGrade) => {
    if (value.id !== selectedGrade.id) {
      setSelectedGrade(value);
      await onGetData({
        grade_id: value.id,
        page: 1,
      });
    }
  };
  const [selectedType, setSelectedType] = useState<SelectType>(programType[0]);

  const handleType = async (value: any) => {
    setSelectedType(value);
    onGetData({
      type: value.type,
      page: 1,
    });
  };

  const handleSort = async (value: SORT) => {
    if (value.id !== sort.id) {
      setSort(value);
      await onGetData({
        page: 1,
        sort_by: value.sort_by,
        order_by: value.order_by,
      });
    }
  };

  const handleStatus = async (value: ProgramParams["status"]) => {
    if (value !== status) {
      setStatus(value);
      await onGetData({
        status: value,
        page: 1,
      });
    }
  };

  return (
    <div className="mb-5 flex flex-wrap items-center gap-x-5 gap-y-3">
      <div className="bg-white px-4 py-2.5 rounded-xl flex gap-x-2 items-center">
        <FontAwesomeIcon icon={icon({ name: "search" })} />
        <input
          className="w-full"
          placeholder="Cari program..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        {Boolean(search.length) && (
          <FontAwesomeIcon
            icon={icon({ name: "close" })}
            onClick={clearSearch}
            className="cursor-pointer"
          />
        )}
      </div>
      {/* status */}
      <ul className="rounded-lg bg-white w-max outline outline-1 outline-gray-300 flex flex-wrap items-center overflow-hidden">
        {STATUS.map((val, index) => {
          const isPublished = val.value === 0;
          const dotColor = val.value
            ? "bg-orange-500"
            : isPublished
            ? "bg-green-500"
            : "bg-gray-500";
          const isSelected = val.value === status;

          return (
            <li
              key={index}
              className={`w-auto px-4 py-2.5 flex items-center gap-x-2 cursor-pointer hover:bg-primary ${
                isSelected ? "bg-primary" : "bg-white"
              } ${index === 1 && "border-x"}`}
              onClick={() => handleStatus(val.value as ProgramParams["status"])}
            >
              <div className={`w-2.5 h-2.5 rounded-full ${dotColor}`} />
              <p className="text-sm">{val.name}</p>
            </li>
          );
        })}
      </ul>
      {/* programType */}
      <div className="relative flex-1 min-w-[8rem]">
        <Listbox value={selectedType} onChange={handleType}>
          <Listbox.Button className="relative flex w-full cursor-default items-center justify-between gap-x-1 rounded-lg border bg-white px-3.5 py-2.5 text-left text-sm">
            <span className="block truncate">{selectedType.name}</span>
            <FontAwesomeIcon icon={icon({ name: "chevron-down" })} />
          </Listbox.Button>
          <Listbox.Options className="absolute z-10 mt-1 max-h-48 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {programType.map((val, classIdx) => (
              <Listbox.Option
                key={`${classIdx}-${id}`}
                as={Fragment}
                value={val}
              >
                {({ active }) => (
                  <li
                    className={`relative flex items-center justify-between cursor-default select-none px-3.5 py-2.5 ${
                      active ? "bg-gray-50" : "text-gray-900"
                    }`}
                  >
                    <span className="block truncate">{val.name}</span>
                    {val.type === selectedType.type && (
                      <FontAwesomeIcon
                        icon={icon({ name: "check" })}
                        color="#7F56D9"
                      />
                    )}
                  </li>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Listbox>
      </div>
      {/* grades */}
      <div className="relative flex-1 min-w-[12rem]">
        <Listbox value={selectedGrade} onChange={handleGrade}>
          <Listbox.Button className="relative flex w-full cursor-default items-center justify-between gap-x-1 rounded-lg border bg-white px-3.5 py-2.5 text-left text-sm">
            <span className="block truncate">{selectedGrade.name}</span>
            <FontAwesomeIcon icon={icon({ name: "chevron-down" })} />
          </Listbox.Button>
          <Listbox.Options className="absolute z-10 mt-1 max-h-48 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {[allGrade, ...grades].map((val, classIdx) => (
              <Listbox.Option
                key={`${classIdx}-${id}`}
                as={Fragment}
                value={val}
              >
                {({ active }) => (
                  <li
                    className={`relative flex items-center justify-between cursor-default select-none px-3.5 py-2.5 ${
                      active ? "bg-gray-50" : "text-gray-900"
                    }`}
                  >
                    <span className="block truncate">{val.name}</span>
                    {val.id === selectedGrade.id && (
                      <FontAwesomeIcon
                        icon={icon({ name: "check" })}
                        color="#7F56D9"
                      />
                    )}
                  </li>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Listbox>
      </div>
      {/* sort */}
      <div className="relative flex-1 min-w-[10rem]">
        <Listbox value={sort} onChange={handleSort}>
          <Listbox.Button className="relative flex w-full cursor-default items-center justify-between gap-x-1 rounded-lg border bg-white px-3.5 py-2.5 text-left text-sm">
            <span className="block truncate">{sort.name}</span>
            <FontAwesomeIcon icon={icon({ name: "chevron-down" })} />
          </Listbox.Button>
          <Listbox.Options className="absolute z-10 mt-1 max-h-48 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {SORT_LIST.map((val, classIdx) => (
              <Listbox.Option
                key={`${classIdx}-${id}`}
                as={Fragment}
                value={val}
              >
                {({ active }) => (
                  <li
                    className={`relative flex items-center justify-between cursor-default select-none px-3.5 py-2.5 ${
                      active ? "bg-gray-50" : "text-gray-900"
                    }`}
                  >
                    <span className="block truncate">{val.name}</span>
                    {val.id === sort.id && (
                      <FontAwesomeIcon
                        icon={icon({ name: "check" })}
                        color="#7F56D9"
                      />
                    )}
                  </li>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Listbox>
      </div>
    </div>
  );
};

export default memo(Filter);
