import { useCallback } from "react";
import { services } from "services";
import { API_URL } from "constant";
import { AxiosError } from "axios";

const useWithdrawal = () => {

  const getListTotal = useCallback(
    async (
      params: UserPointParams
    ): Promise<PointListResponse<UserPointsSummary[]>> => {
      try {
        const response = await services.get(`${API_URL.point}`, {
          params,
        });

        return response?.data?.data;
      } catch (error) {
        const err = error as AxiosError;
        throw err.response?.data;
      }
    },
    []
  );

  const getListByUserId = useCallback(
    async (
      params: UserPointParams,
      userId: string
    ): Promise<PointListResponse<UserPoints[]>> => {
      try {
        const response = await services.get(`${API_URL.point}/${userId}`, {
          params,
        });

        return response?.data?.data;
      } catch (error) {
        const err = error as AxiosError;
        throw err.response?.data;
      }
    },
    []
  );

  return {
    getListTotal,
    getListByUserId
  };
};

export default useWithdrawal;
