import { Input, InputNumber, Label } from "components";
import { FC, memo } from "react";
import { useFormContext, Controller } from "react-hook-form";

type Props = {
  isReview?: boolean;
};

const StaffInfo: FC<Props> = ({ isReview }) => {
  const { control } = useFormContext<StudentPayload>();

  return (
    <div className="bg-white rounded-xl">
      <p className="font-bold p-5 border-b">Informasi Admin</p>
      <div className="p-5 space-y-5">
        <Controller
          control={control}
          name="name"
          render={({ field }) => (
            <Input
              label="Nama admin"
              placeholder="Masukkan nama admin"
              disabled={isReview}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />

        <Controller
          control={control}
          name="email"
          render={({ field }) => (
            <Input.Email
              label="Email"
              disabled={isReview}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="phone_number"
          render={({ field }) => (
            <div>
              <Label>No. handphone</Label>
              <div
                className={`w-full flex items-center gap-x-2 mt-2 border rounded-lg px-3.5 py-2.5 ${
                  isReview ? "bg-gray-50" : "bg-white"
                }`}
              >
                <span>+62</span>
                <InputNumber
                  label=""
                  placeholder="Masukkan nomor handphone"
                  disabled={isReview}
                  value={field.value}
                  onChange={field.onChange}
                  className="w-full"
                  options={{
                    phone: true,
                    phoneRegionCode: "ID",
                  }}
                />
              </div>
            </div>
          )}
        />
        <Controller
          control={control}
          name="password"
          render={({ field }) => (
            <Input.Password
              label="Password"
              requirement="Minimal 8 karakter terdiri dari huruf kapital, huruf kecil, angka dan
          karakter spesial"
              disabled={isReview}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </div>
    </div>
  );
};

export default memo(StaffInfo);
