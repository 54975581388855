import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "components/Modal";
import { FC, memo } from "react";

type Props = {
  show: boolean;
  onClose: () => void;
};

const SuccessAdd: FC<Props> = ({ show, onClose }) => {
  return (
    <Modal
      title=""
      show={show}
      onClose={onClose}
      customFooter={
        <div className="grid place-items-center">
          <button
            className="py-[0.625rem] px-4 rounded-xl bg-primary"
            onClick={onClose}
          >
            Tutup
          </button>
        </div>
      }
    >
      <div className="grid place-items-center gap-y-10 text-center">
        <FontAwesomeIcon
          icon={icon({ name: "check" })}
          className={"w-10 h-10 text-white rounded-full p-1.5 bg-green-500"}
        />
        <div className="space-y-4 w-96">
          <p className="font-bold text-2xl">Staff Sekolah berhasil ditambahkan </p>
          <p className="text-gray-600">
            Staff Sekolah yang baru saja ditambahkan akan otomatis menerima email
            pemberitahuan.
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default memo(SuccessAdd);
