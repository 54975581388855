// API
import { services } from "services"

// Components
import { Card, Content, Spinner } from "components";
import {FormSection} from '../components';
import Breadcrumbs from 'components/Breadcrumbs';

// Form
import { FormProvider, useFieldArray, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// Third-Party Libraries
import toast from "react-hot-toast"
import { useNavigate, useParams } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

// Types
import { ErrorFetch, SuccessFetch } from "types"
import { FormSyllabus, Form as FormType } from "../types"

// Utilities
import { baseData, validationSchema } from "../utilities"
import { API_URL } from "constant";

// View Models
import { Single, useSingle } from "./viewModels"
import { useState } from "react";
import Meeting from "pages/Syllabus/fragment/Meeting";

export type Form = FormType

const defaultCrumbs: Crumb[] = [
  {
    label: 'Silabus',
    id: 0,
  },
  {
    label: 'Ubah Silabus',
    id: 1,
  }
];

export default function Ubah(): JSX.Element {
  // Hooks
  const param = useParams();
  const navigate = useNavigate();

  // Variables
  const id = param.id ?? "";

  // Hooks
  const { data, isLoading } = useSingle(id);

  return (
    isLoading ? (
      <Spinner />
    ) : (
      <DataSection data={data} id={id} />
    )
  );
};

function DataSection(props: {
  data: Single
  id: string
}) {
  // Hooks
  const navigate = useNavigate();

  const [isMeeting, setIsMeeting] = useState(false);
  const [packageIndex, setPackageIndex] = useState(0);
  const [isEdit, setIsEdit] = useState(false);

  // API
  const methods = useForm<FormSyllabus>({
    defaultValues: {
      grade_id: props.data.grade_id,
      subject_id: null,
      major_id: null,
      name: props.data.name,
      description: props.data.description,
      packages: props.data.packages,
    }
  });
  const {control, handleSubmit} = methods;
  const {insert, remove, fields} = useFieldArray({
    control,
    name: 'packages'
  })

  const onSubmit = (values: FormSyllabus) => {
    const payload: AddSyllabusParams = {
      description: values.description,
      grade_id: values.grade_id,
      major_id: values.major_id,
      name: values.name,
      subject_id: values.subject_id,
      packages: values.packages.map((packageItem) => ({
        content_type: packageItem.content_type,
        description: packageItem.description,
        title: packageItem.title,
        directories: packageItem.directories.map((dir) => ({
          id: dir.directoryId,
          module_ids: dir.modules.map((module) => module.module_id),
          questions: dir.questions.map((question) => ({
            n_essay: question.n_essay,
            n_multiple_choice: question.n_multiple_choice,
            question_package_id: question.question_package_id,
            type: String(question.type)
          }))
        }))
      }))
    };

    toast.promise(
      services.put(`${API_URL.syllabus}/${props.id}`, payload),
      {
        loading: "Loading...",
        success: (res: SuccessFetch<{message: string}>) => {
          navigate(baseData.url, { replace: true, state: { useCache: true } })
          return res.data.message
        },
        error: (err: ErrorFetch) => err.response.data.message
      }
    )
  };

  const handleCrumb = (item: Crumb) => {
    if (item.id === 0) {
      navigate('/silabus');
    }
  };

  const handleMeeting = (id?: number) => {
    if (id !== undefined) {
      if (!isEdit) {
        remove(id);
      }
      setIsMeeting(false);
    } else {
      const packageLength = fields.length;

      insert(packageLength, {
        content_type: 'mixed',
        description: '',
        directories: [],
        title: ''
      });
      setIsEdit(false);
      setPackageIndex(packageLength);
      setIsMeeting(true);
    }
  };

  const editMeeting = (index: number) => {
    setIsEdit(true);
    setPackageIndex(index);
    setIsMeeting(true);
  };

  return (
    <>
      {!isMeeting && (
        <Breadcrumbs crumbs={defaultCrumbs} onClick={(item) => handleCrumb(item)} />
      )}
      <FormProvider {...methods}>
        {isMeeting ? (
          <Meeting
            onCancel={() => handleMeeting(packageIndex)}
            onSubmit={() => setIsMeeting(false)}
            index={packageIndex}
          />
        ) : (
          <>
            <FormSection
              onSetMeeting={() => handleMeeting()}
              onEditMeeting={(idx) => editMeeting(idx)}
            />
            <section className="flex py-5 gap-x-3 justify-end">
              <button
                onClick={() => navigate('/silabus')}
                className="px-4 py-[0.625rem] rounded-xl border border-primary bg-white hover:bg-primary"
              >
                Batal
              </button>
              <button
                onClick={handleSubmit((data) => {onSubmit(data)})}
                className="px-4 py-[0.625rem] rounded-xl bg-primary"
              >
                <FontAwesomeIcon
                  icon={icon({name: 'floppy-disk', style:'regular'})}
                  className='mr-[0.625rem]'
                />
                Simpan
              </button>
            </section>
          </>
        )}
      </FormProvider>
    </>
  );
};
