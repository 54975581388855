import { services } from "services";
import { API_URL } from "constant";

const useOrder = () => {
  const getOrderSummary = async (): Promise<summaryOrderData> => {
    try {
      const response = await services.get(`${API_URL.transactions}/summary`);

      return response?.data?.data;
    } catch (error) {
      throw error;
    }
  };

  const getOrderData = async (
    params?: TransactionParams
  ): Promise<OrderList<PaymentDetails[]>> => {
    try {
      const {
        page = 1,
        per_page = 10,
        keyword,
        sort_by,
        order_by,
        transaction_status,
        purchase_type,
      } = params ?? {};
      let payload: Partial<TransactionParams> = {
        page,
        per_page,
        keyword,
        sort_by,
        order_by,
        transaction_status,
        purchase_type,
      };

      const response = await services.get(`${API_URL.transactions}`, {
        params: payload,
      });

      return response?.data?.data;
    } catch (error) {
      throw error;
    }
  };

  const getOrderDetail = async (id: string): Promise<TrasactionDetail> => {
    try {
      const response = await services.get(
        `${API_URL.transactions}/payment/${id}`
      );
      const result = response?.data?.data ?? {};
      if (result) {
        result.details = JSON.parse(result.details);
      }
      return result;
    } catch (error) {
      throw error;
    }
  };

  return {
    getOrderSummary,
    getOrderData,
    getOrderDetail,
  };
};

export default useOrder;
