import { useRef, useCallback } from "react";
import { services } from "services";
import { API_URL } from "constant";
import { AxiosError } from "axios";

type Response = PaginationResponse & {
  data: BrowseItem[];
};

const defaultParams: BrowseParams = {
  page: 1,
  per_page: 10,
  sort_by: "name",
  sort_dir: "asc",
};

const useBrowse = (includes?: BrowseParams["includes"]) => {
  const lastPayload = useRef(defaultParams);

  const getList = useCallback(
    async (params?: BrowseParams) => {
      try {
        let payload = {
          includes,
          ...lastPayload.current,
          ...params,
        };

        if (params?.directory_id) {
          payload = {
            ...payload,
            directory_id: params.directory_id,
          };
        }

        lastPayload.current = payload;
        const response = await services.get(API_URL.browse, {
          params: payload,
        });

        return response.data?.data;
      } catch (error) {
        return [];
      }
    },
    [includes]
  );

  const getListAdmin = useCallback(
    async (params?: BrowseParams): Promise<Response> => {
      try {
        let payload = {
          includes,
          ...lastPayload.current,
          ...params,
        };

        if (params?.directory_id) {
          payload = {
            ...payload,
            directory_id: params.directory_id,
          };
        }

        lastPayload.current = payload;
        const response = await services.get(API_URL.adminBrowse, {
          params: payload,
        });

        return response.data?.data;
      } catch (error) {
        return {
          data: [],
          current_page: 1,
          from: 1,
          last_page: 1,
          per_page: params?.per_page || 10,
          to: 1,
          total: 1,
        };
      }
    },
    [includes]
  );
  const changeParent = async (params: {
    item: BrowseItem | null;
    parent: BrowseItem | null;
  }): Promise<{ success: boolean; message?: string }> => {
    try {
      if (!params.item) {
        throw new Error("Item harus ada");
      }

      if (!params.parent) {
        throw new Error("Parent harus ada");
      }
      const validateParent = params.parent.type === "directory";
      if (!validateParent) {
        throw new Error("Parent harus directory");
      }
      const requestBody = {
        ...params.item,
        parent_id: params.parent.id,
        parent_type: params.parent.type,
      };

      await services.post(API_URL.moveItem, requestBody);
      return { success: true };
    } catch (error) {
      const err = error as AxiosError;
      const errMessage = err.response?.data as any;

      return { success: false, message: errMessage?.message };
    }
  };

  return {
    getList,
    getListAdmin,
    changeParent,
  };
};

export default useBrowse;
