import {useCallback} from 'react';
import {services} from 'services';
import {API_URL} from 'constant';
import { AxiosError } from 'axios';

const useProgram = () => {
  const getDetail = useCallback(async (id: number) => {
    try {
      const response = await services.get(`${API_URL.program}/${id}/class`);

      return response.data;
    } catch (error) {
      const err = error as AxiosError;

      throw err.response?.data;
    }
  }, []);

  const getClassMeets = useCallback(async (id: number) => {
    try {
      const response = await services.get(`${API_URL.program}/${id}/class/meets`);

      return response.data;
    } catch (error) {
      const err = error as AxiosError;

      throw err.response?.data;
    }
  }, []);

  const getClassResume = useCallback(async (id: number) => {
    try {
      const response = await services.get(`${API_URL.program}/${id}/class/meet/resume`);

      return response.data;
    } catch (error) {
      const err = error as AxiosError;

      throw err.response?.data;
    }
  }, []);

  const updateDetail = async (id: number, data?: LiveClassUpdateParams) => {
    try {
      await services.put(`${API_URL.program}/${id}/class`, data)
    } catch (error) {
      throw error;
    }
  }

  const getClassByProgramId = useCallback(async (id: number) => {
    try {
      const response = await services.get(`${API_URL.program}/${id}/class/meet/classroom`)
      return response.data
    } catch (error) {
      const err = error as AxiosError;

      throw err.response?.data;
    }
  }, []);

  const getTeacherByProgramId = useCallback(async (programId: number, classCode: string, keyword: string) => {
    try {
      const response = await services.get(`${API_URL.teacher}/liveclass/${programId}/available/${classCode}`, {params: {keyword}})
      return response.data
    } catch (error){
      const err = error as AxiosError;

      throw err.response?.data;
    }
  }, []);

  const getClassDetailByClassCode = useCallback(async (programId: number, classCode: string) => {
    try {
      const response = await services.get(`${API_URL.program}/${programId}/class/meet/classroom/detail/${classCode}`)
      return response.data
    } catch (error){
      const err = error as AxiosError;

      throw err.response?.data;
    }
  }, []);

  const getClassItemsByClassCode = useCallback(async (programId: number, classCode: string, perPage: number = 10) => {
    try {
      const response = await services.get(`${API_URL.program}/${programId}/class/meet/classroom/class/${classCode}?per_page=${perPage}`)
      return response.data
    } catch (error){
      const err = error as AxiosError;

      throw err.response?.data;
    }
  }, []);

  const assignTeacherToClass = async (programId: number, classCode: string, teacherId: number) => {
    try {
      await services.patch(`${API_URL.program}/${programId}/class/meet/classroom/assign-teacher/${classCode}`, {
        teacher_id: teacherId
      })
    } catch (error) {
      throw error;
    }
  }

  const updateTeacherToClass = async (programId: number, classCode: string, teacherId: number) => {
    try {
      await services.patch(`${API_URL.program}/${programId}/class/meet/classroom/change-teacher/${classCode}`, {
        teacher_id: teacherId
      })
    } catch (error) {
      throw error;
    }
  }

  const getStudentsByProgram = useCallback(async (programId: number) => {
    try {
      const response = await services.get(`${API_URL.program}/${programId}/class/students`)
      return response.data
    } catch (error){
      const err = error as AxiosError;

      throw err.response?.data;
    }
  }, []);

  const generateClass = useCallback(async (programId: string, params: GenerateClassParams) => {
    try {
      const response = await services.post(`${API_URL.program}/${programId}/class/meets/classroom`,params);

      return response.data?.data;
    } catch (error) {
      const err = error as AxiosError;

      throw err.response?.data;
    }
  }, []);

  const getMonitoringLiveClass = useCallback(async (params: LiveClassMonitoringParams) => {
    try {
      const response = await services.get(`${API_URL.liveclass}/monitoring`, { params })
      return response.data
    } catch (error){
      const err = error as AxiosError;

      throw err.response?.data;
    }
  }, []);

  const getLiveClassSchedules = useCallback(async (start_date: string) => {
    try {
      const response = await services.get(`${API_URL.liveclass}/calenders?start_date=${start_date}`);
      return response.data
    } catch (error){
      const err = error as AxiosError;

      throw err.response?.data;
    }
  }, []);

  const generateMeetToken = useCallback(async (programId: string, classId: string) => {
    try {
      const response = await services.post(`${API_URL.liveclass}/program/${programId}/meeting/${classId}/generate_token`);

      return response.data?.data;
    } catch (error) {
      const err = error as AxiosError;

      throw err.response?.data;
    }
  }, []);

  const getLiveMeetStudents = useCallback(async (params: LiveClassStudentsParams) => {
    try {
      const { program_id: programId, meet_class_id: classId, ...restParams } = params
      const response = await services.get(`${API_URL.liveclass}/program/${programId}/meeting/${classId}/students`,{
        params: restParams
      })
      return response.data
    } catch (error){
      const err = error as AxiosError;

      throw err.response?.data;
    }
  }, []);

  const getLiveMeetDetail = useCallback(async (programId: string, classId: string) => {
    try {
      const response = await services.get(`${API_URL.liveclass}/program/${programId}/meeting/${classId}/detail`)
      return response.data
    } catch (error){
      const err = error as AxiosError;

      throw err.response?.data;
    }
  }, []);

  return {
    getDetail,
    getClassMeets,
    getClassResume,
    updateDetail,
    getClassByProgramId,
    getTeacherByProgramId,
    getClassDetailByClassCode,
    getClassItemsByClassCode,
    assignTeacherToClass,
    updateTeacherToClass,
    getStudentsByProgram,
    generateClass,
    getMonitoringLiveClass,
    getLiveClassSchedules,
    generateMeetToken,
    getLiveMeetStudents,
    getLiveMeetDetail,
  }
};

export default useProgram;
