import { Button, Input, InputNumber, Label } from "components";
import Modal from "components/Modal";
import { FC, memo, useMemo } from "react";
import { useForm, Controller } from "react-hook-form";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import {
  validateEmail,
  validateLength,
  validateLowercase,
  validateNumber,
  validateSpecialChar,
  validateUppercase,
} from "utilities";
import useStaff from "services/useStaff";

type Props = {
  show: boolean;
  onClose: () => void;
  onSuccess: (data: StaffData) => void;
  data: StaffData;
};

const Edit: FC<Props> = ({ show, onClose, data, onSuccess }) => {
  const { edit } = useStaff();
  const { pathname } = useLocation();
  const id = pathname.split("/admin/")[1];
  const { control, setValue, watch, handleSubmit } = useForm<StaffPayload>({
    defaultValues: {
      name: data.name,
      email: data.email,
      phone_number: data.phone_number,
      password: "",
      role: "admin",
      phone_code: "62",
    },
  });
  const { name, email, phone_number, password } = watch();

  const validateStaffInfo = useMemo(() => {
    const validEmail = validateEmail(email);
    const isLowercase = validateLowercase(String(password));
    const isUppercase = validateUppercase(String(password));
    const isNumber = validateNumber(String(password));
    const isLength = validateLength(String(password), 8);
    const isSpecialChar = validateSpecialChar(String(password));
    const validPassword =
      !password ||
      (isLowercase && isUppercase && isNumber && isLength && isSpecialChar);

    return Boolean(name && validEmail && phone_number && validPassword);
  }, [name, email, phone_number, password]);
  const canSubmit = useMemo(() => {
    const validEmail = validateEmail(String(email));
    const validStaffInfo = validateStaffInfo;
    return Boolean(name && validEmail && phone_number && validStaffInfo);
  }, [email, validateStaffInfo, name, phone_number]);

  const handleClose = () => {
    onClose();
    setValue("name", data.name);
    setValue("email", data.email);
    setValue("phone_number", data.phone_number);
  };

  const handleEdit = (params: StaffPayload) => {
    toast.promise(edit(id, params), {
      loading: "...",
      success: () => {
        const newData = {
          name: params.name,
          email: params.email,
          phone_number: params.phone_number,
        };
        onSuccess(newData as StaffData);
        return "Data berhasil diperbarui";
      },
      error: (err) => {
        return err;
      },
    });
  };

  return (
    <Modal
      title="Edit Admin"
      show={show}
      onClose={onClose}
      customFooter={
        <div className="float-right space-x-2">
          <Button.Cancel onButtonClick={handleClose} />
          <Button
            color="primary"
            isDisabled={!canSubmit}
            onButtonClick={handleSubmit((data) => handleEdit(data))}
          >
            Simpan
          </Button>
        </div>
      }
    >
      <div className="grid grid-cols-2 gap-x-[3.75rem]">
        <div className="space-y-5">
          <Controller
            control={control}
            name="name"
            render={({ field }) => (
              <Input
                label="Nama"
                placeholder="Nama Admin"
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <Input.Email
                label="Email"
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            control={control}
            name="phone_number"
            render={({ field }) => (
              <div>
                <Label>No. handphone</Label>
                <div className="`w-full flex items-center gap-x-2 mt-2 border rounded-lg px-3.5 py-2.5 bg-white">
                  <span>+62</span>
                  <InputNumber
                    label=""
                    placeholder="Masukkan nomor handphone"
                    value={field.value}
                    onChange={field.onChange}
                    className="w-full"
                    options={{
                      phone: true,
                      phoneRegionCode: "ID",
                    }}
                  />
                </div>
              </div>
            )}
          />
          <Controller
            control={control}
            name="password"
            render={({ field }) => (
              <Input.Password
                label="Password"
                requirement="Minimal 8 karakter terdiri dari huruf kapital, huruf kecil, angka dan
            karakter spesial"
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </div>
      </div>
    </Modal>
  );
};

export default memo(Edit);
