import { useCallback, useState } from "react";
import { Button, Spinner } from "components";
import useBundle from "services/useBundle";
import Filter from "./fragment/Filter";
import Item from "./fragment/Item";
import { DEFAULT_PAGINATION } from "constant";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import useLeave from "services/useLeave";
import Footer from "./fragment/Footer";

export default function Leave() {
  const navigate = useNavigate();
  const { getList, updateStatus } = useLeave();

  const [loading, setLoading] = useState(false);
  const [leaveList, setLeaveList] = useState<LeaveItem[]>([]);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);

  const getData = useCallback(async (params?: LeaveListParam) => {
    setLoading(true);
    const response = await getList(params);
    const { data, ...restResponse } = response;

    setLeaveList(data);
    setPagination(restResponse);
    setLoading(false);
  }, []);

  const handleUpdate = async (item: LeaveItem) => {
    const { id, status } = item;

    toast.promise(updateStatus(id, status), {
      loading: `Memperbaharui status cuti...`,
      success: () => {
        getData();
        return `Berhasil memperbaharui status cuti`;
      },
      error: `Gagal memperbaharui status cuti`,
    });
  };

  const navigateAdd = () => {
    navigate("tambah", {
      relative: "path",
    });
  };

  return (
    <>
      <div className="flex items-center justify-between mb-5">
        <div>
          <p className="font-bold">{`Total ${pagination.total} permintaan cuti`}</p>
          <p className="text-xs text-gray-600">Seluruh cuti yang dibuat</p>
        </div>
      </div>
      <Filter onGetData={getData} />
      {loading ? (
        <Spinner />
      ) : leaveList.length ? (
        <div className="space-y-4">
          {leaveList.map((val) => (
            <Item key={val.id} data={val} onUpdate={handleUpdate} />
          ))}
        </div>
      ) : (
        <p className="italic text-center">Tidak ada data</p>
      )}

      <Footer pagination={pagination} onGetData={getData} />
    </>
  );
}
