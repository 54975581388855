import Breadcrumbs from "components/Breadcrumbs";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Info from "./fragment/Detail/Information";
import useSchoolStaff from "services/useSchoolStaff";



const Detail = () => {
  const { getDetail } = useSchoolStaff();
  const navigate = useNavigate();
  const location = useLocation();
  const pathId = location.pathname.split("/school-staff/")[1];
  const [detail, setDetail] = useState<SchoolStaffData | null>(null);

  useEffect(() => {
    getDetail(pathId)
      .then((resp) => setDetail(resp))
      .catch(() => {
        toast.error("Data tidak ditemukan");
        navigate("/school-staff", {
          relative: "path",
        });
      });
    // eslint-disable-next-line
  }, [pathId]);

  const handleCrumb = (item: Crumb) => {
    navigate("/school-staff", {
      replace: true,
    });
  };

  return (
    <div className="space-y-5">
      <Breadcrumbs crumbs={["Staff Sekolah", "Detail staff sekolah"]} onClick={handleCrumb} />
      <div className="flex gap-x-8">
        <Info data={detail} />
      </div>
    </div>
  );
};

export default Detail;
