import moment from "moment";
import Table from "../../../components/Table";
import { useEffect, useState } from "react";
import { currencyConverter } from "../../../utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import usePoint from "services/usePoint";
const columns = [
  {
    title: "Nama Siswa",
    isSort: true,
    key: "name",
  },
  {
    title: "Jumlah Poin",
    isSort: true,
    key: "total_point",
  },
  {
    title: "Catatan Terakhir",
    isSort: true,
    key: "remark",
  },
  {
    title: "Transaksi Terakhir",
    isSort: true,
    key: "created_at",
  },
];

const GET_LIST_DEFAULT_PARAMS: WithdrawalListParams = {
  per_page: 10,
  page: 1,
  orderBy: "asc",
};

const WithdrawalList = () => {
  const { getListTotal } = usePoint();
  const [data, setData] = useState<PointListResponse<UserPointsSummary[]>> ();
  const [sortFilterData, setSortFilterData] = useState<WithdrawalListParams>(
    GET_LIST_DEFAULT_PARAMS
  );

  useEffect(() => {
    getListTotal(sortFilterData).then((resp) => {
      setData(resp);
    });
  }, [getListTotal, sortFilterData]);

  const handleLimit = async (limit: string) => {
    setSortFilterData((prev) => ({ ...prev, per_page: Number(limit) }));
  };

  const handlePagination = async (ctrl: "next" | "prev") => {
    if (data) {
      const currentPage = data.current_page;
      const lastPage = data.last_page;
      const isNext = ctrl === "next";
      const page = isNext ? currentPage + 1 : currentPage - 1;

      if (page > 0 && page <= lastPage) {
        setSortFilterData((prev) => ({ ...prev, page: page }));
      }
    }
  };

  const handleSort = async (sortParams: string) => {
    const currentSortBy = sortFilterData.sortBy;
    const currentOrderBy = sortFilterData.orderBy;
    const reverseOrderBy = currentOrderBy === "asc" ? "desc" : "asc";
    const selectedOrderBy =
      currentSortBy === sortParams ? reverseOrderBy : "asc";
    setSortFilterData((prev) => ({
      ...prev,
      sortBy: sortParams,
      orderBy: selectedOrderBy,
    }));
  };

  if (!data) return null;
  return (
    <div className="space-y-4">
      <div className={"flex space-x-4 items-center"}>
        <p
          className={"text-md font-bold text-gray-600 flex flex-1"}
        >{`Total ${data.total} data`}</p>
        <Searchbar
          search={sortFilterData.keyword ?? ""}
          setSearch={(val) =>
            setSortFilterData({ ...sortFilterData, keyword: val })
          }
        />
      </div>
      <Table
        data={data?.data ?? []}
        columns={columns}
        currentLimit={data.per_page}
        currentPage={data.current_page}
        total={data.total}
        handleLimit={handleLimit}
        handlePagination={handlePagination}
        handleSort={handleSort}
        handleFirstColumn={"w-1/4"}
      >
        <>
          {data?.data.length ? (
            data?.data?.map((item) => {
              const { id, remark, created_at, total_point, name } =
                item;;
              return (
                <tr
                  key={id}
                  className="border-b cursor-pointer hover:bg-gray-100"
                >
                  <td className="p-4">{name}</td>
                  <td className="p-4 text-end">
                    {currencyConverter(total_point, true)}
                  </td>
                  <td className="p-4 text-[#7A7B7E]">
                    {moment(created_at).format("DD/MM/YYYY")}
                  </td>
                  <td className="p-4 text-base text-gray-600 text-base">
                    {remark}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td
                colSpan={columns.length}
                className="italic opacity-40 text-center pt-3"
              >
                <span>Tidak ada data.</span>
              </td>
            </tr>
          )}
        </>
      </Table>
    </div>
  );
};

const Searchbar = ({
  search,
  setSearch,
}: {
  search: string;
  setSearch: (val: string) => void;
}) => {
  return (
    <div className="bg-white px-4 py-[0.625rem] rounded-xl w-72 flex gap-x-2 items-center border">
      <FontAwesomeIcon icon={icon({ name: "search" })} color={"#667085"} />
      <input
        className="w-full"
        placeholder="Cari nama pengajar..."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      {Boolean(search.length) && (
        <FontAwesomeIcon
          icon={icon({ name: "close" })}
          onClick={() => setSearch("")}
          className="cursor-pointer"
        />
      )}
    </div>
  );
};

export default WithdrawalList;
