import { useCallback, useEffect, useState } from "react";
import { Spinner } from "components";
import Table from "components/Table";
import DetailOrderModal from "./fragment/DetailOrder";
import Filter from "./fragment/Filter";
import useOrder from "services/useOrder";
import moment from "moment";
import toast from "react-hot-toast";
import * as XLSX from "xlsx";

const defaultPagination: PaginationResponse = {
  current_page: 1,
  per_page: 10,
  last_page: 1,
  from: 1,
  to: 1,
  total: 1,
};

const columns = [
  {
    title: "#ID pembayaran",
    isSort: true,
    key: "id",
  },
  {
    title: "Nama Pembeli",
    isSort: true,
    key: "user_name",
  },
  {
    title: "Jenis Pembelian",
    isSort: true,
    key: "type",
  },
  {
    title: "Jumlah Pembayaran",
    isSort: true,
    key: "amount",
  },
  {
    title: "Metode Pembayaran",
    isSort: true,
    key: "payment_method_name",
  },
  {
    title: "Tanggal Pemesanan",
    isSort: true,
    key: "created_at",
  },
  {
    title: "Status Pembayaran",
    isSort: true,
    key: "status",
  }
];


const OrderHistory = () => {
  const { getOrderData } = useOrder();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<PaymentDetails[]>([]);
  const [pagination, setPagination] =
    useState<PaginationResponse>(defaultPagination);
  const [modalShow, setModalShow] = useState(false);
  const [selectedId, setSelectedId] = useState("0");
  const [sort, setSort] = useState<TransactionParams["sort_by"]>("created_at");
  const [order, setOrder] = useState<TransactionParams["order_by"]>("desc");
  const [currentParams, setCurrentParams] = useState<TransactionParams>({
    sort_by: "created_at",
    order_by: "desc",
    transaction_status: "all",
    purchase_type: "all",
    per_page: 10,
    keyword: "",
    page: 1,
  });

  const formatCurrency = (number: number) => {
    const formatted = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    }).format(number);

    return formatted;
  };

  const toggleModal = (id?: string) => {
    setModalShow(!modalShow);
    if (id) {
      setSelectedId(id);
    }
  };

  const getData = useCallback(
    async (params: TransactionParams) => {
      setLoading(true);
      const response = await getOrderData(params);
      const { sort_by, order_by } = params ?? {};
      const { data: responseData, ...restResponse } = response;
      setCurrentParams(params)
      setData(responseData);
      setPagination(restResponse);
      order_by && setOrder(order_by);
      sort_by && setSort(sort_by);
      setLoading(false);
    },
    [getOrderData]
  );

  useEffect(() => {
    getData(currentParams);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePagination = async (ctrl: "next" | "prev") => {
    const isNext = ctrl === "next";
    const page = isNext
      ? pagination.current_page + 1
      : pagination.current_page - 1;
    if (page > 0 && page <= pagination.last_page) {
      const newParams = {
        ...currentParams,
        page: page,
        per_page: pagination.per_page,
        order_by: order,
        sort_by: sort,
      }
      setCurrentParams(newParams);
      await getData(newParams);
    }
  };

  const handleSort = (param: string) => {
    let direction = order === "asc" ? "desc" : "asc";

    if (param !== sort) {
      direction = "asc";
    }

    setOrder(direction as TransactionParams["order_by"]);
    setSort(param as TransactionParams["sort_by"]);
    const newParams = {
      ...currentParams,
      sort_by: param as TransactionParams["sort_by"],
      order_by: direction as TransactionParams["order_by"],
    }
    setCurrentParams(newParams);
    getData(newParams);
  };

  const handleLimit = async (limit: string) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      per_page: parseInt(limit),
    }));

    const newParams = {
      ...currentParams,
      per_page: Number(limit),
    }
    setCurrentParams(newParams);
    await getData(newParams);
  };


  const mapDataToNewColumnNames = (data: PaymentDetails[]) => {
    const columnMapping: { [key: string]: string } = {
      id: 'ID',
      user_id: 'ID User',
      inovice_no: 'nomor_invoice',
      user_name: 'Nama Pembeli',
      user_phone: 'Nomor Telepon',
      email: 'Email',
      user_role: 'Jenis user',
      amount: 'Nominal',
      fee: 'Biaya Admin',
      payment_method_name: 'Metode Pembayaran',
      pay_on: 'Tipe Pembelian',
      status: 'Status Transaksi',
      is_paid: 'Sudah Lunas',
      created_at: 'Tanggal Transaksi',
      reference_number: 'Nomor Referensi',
    };

    const columnsToExclude: string[] = ['payment_method_id', 'updated_at', 'payment_icon', 'payment_method_id', 'details']; // Add columns to exclude here

    return data.map((item: any) => {
      const newItem: { [key: string]: any } = {};
      for (const key in item) {
        if (columnsToExclude.includes(key)) {
          continue; // Skip the column if it's in the exclusion list
        }
        if (columnMapping[key]) {
          newItem[columnMapping[key]] = item[key];
        } else {
          newItem[key] = item[key]; // Keep the original key if not in the mapping
        }
      }
      return newItem;
    });
  };

  const handleDownload = async () => {
    const downloadParams: TransactionParams = {
      ...currentParams,
      page: 1,
      per_page: pagination?.total ?? 0,
    };
    const loading = toast.loading("Sedang mengunduh...");
    await getOrderData(downloadParams)
      .then((resp) => {
        toast.success("Berhasil mengunduh.", { id: loading });
        const downloadData = mapDataToNewColumnNames(data ?? []);
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(downloadData);
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, `transaksi-${moment().format("DD-MM-YYYY")}-${downloadParams.transaction_status ?? ''}.xlsx`);
      })
      .catch(() => {
        toast.error("Gagal mengunduh silahkan coba lagi nanti", {
          id: loading,
        });
      });
  };


  return (
    <>
      <div className="bg-white mt-5 rounded-xl">
        <div className="flex flex-row justify-between border-b px-5 py-4 items-center">
        <p className="font-bold">Daftar Penjualan</p>
        <button
          onClick={handleDownload}
          className="px-[16px] py-[10px] border-none bg-[#FFF200] flex justify-center items-center text-black rounded-[12px] font-semibold"
        >Download</button>
        </div>
        <Filter onGetData={getData} params={currentParams} />
        <Table
          data={data}
          columns={columns}
          currentLimit={pagination?.per_page}
          currentPage={pagination.current_page}
          total={pagination?.total}
          handleFirstColumn="w-fit"
          handleLimit={handleLimit}
          handlePagination={handlePagination}
          handleSort={handleSort}
        >
          <>
            {data.length ? (
              data?.map((item, index) => {
                return (
                  <tr
                    key={index}
                    className="border-b hover:bg-yellow-100 cursor-pointer"
                    onClick={() => toggleModal(item.id.toString())}
                  >
                    <td className="p-4 font-[16px]">
                      <div className="flex gap-x-2 items-baseline">
                        <span className="cursor-default text-start">
                          {item.id}
                        </span>
                      </div>
                    </td>
                    <td className="p-4 text-gray-500">{item.user_name}</td>
                    <td className="p-4 text-gray-500">{item.details.type}</td>
                    <td className="p-4 text-gray-500">{formatCurrency(item.amount)}</td>
                    <td className="p-4 text-gray-500">{item.payment_method_name}</td>
                    <td className="p-4 text-gray-500">{item.created_at}</td>
                    <td className="p-4 text-gray-500">
                      {item.status}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td
                  colSpan={columns.length}
                  className="italic opacity-40 text-center pt-3"
                >
                  {loading ? <Spinner /> : <span>Tidak ada data.</span>}
                </td>
              </tr>
            )}
          </>
        </Table>
      </div>
      {modalShow && (
        <DetailOrderModal
          show={modalShow}
          id={selectedId}
          onClose={toggleModal}
        />
      )}
    </>
  );
};

export default OrderHistory;
