// CustomDropdown.tsx
import React from "react";
import Select, { StylesConfig, ActionMeta, SingleValue } from "react-select";
import { addStyles, StaticMathField } from "react-mathquill";
import { commonLatexSymbols } from "constant";

// Add styles for MathQuill
addStyles();

export interface MathOption {
  value: string;
  label: string;
}

interface CustomDropdownProps {
  options: MathOption[];
  question_id: number;
  value?: MathOption;
  onChange: (qustion_id: number, option: MathOption) => void;
  name: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const customStyles: StylesConfig<MathOption, false> = {
  control: (provided) => ({
    ...provided,
    minHeight: "40px",
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0 6px",
  }),
  input: (provided) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: "40px",
  }),
};

const formatOptionLabel = ({ label }: MathOption) => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <CustomOption option={label} />
  </div>
);

const isMathFormula = (text: string) => {
    return commonLatexSymbols.some((symbol) => text.includes(symbol));
  };
  
  const CustomOption = ({ option }: { option: string }) =>
    isMathFormula(option) ? (
      <StaticMathField>{option}</StaticMathField>
    ) : (
      <span>{option}</span>
    );

const CustomDropdown: React.FC<CustomDropdownProps> = ({
  options,
  onChange,
  question_id,
  value,
  name,
  setFieldValue,
}) => {
  const handleChange = (
    newValue: SingleValue<MathOption>,
    actionMeta: ActionMeta<MathOption>
  ) => {
    const selectedOption = options.find(
      (option) => option.value === newValue?.value
    );
    if (selectedOption) {
      onChange(question_id, selectedOption);
      value = selectedOption;
    }

    setFieldValue(name, selectedOption?.label);
  };

  return (
    <Select
      options={options}
      onChange={handleChange}
      styles={customStyles}
      value={value}
      formatOptionLabel={formatOptionLabel}
      getOptionLabel={(option) => option.label}
      getOptionValue={(option) => option.value}
    />
  );
};

export default CustomDropdown;
