import { memo, FC, useState, useEffect } from "react";
import Modal from "components/Modal";
import { Button } from "components";

import useBrowse from "services/useBrowse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useDebounce } from "utilities";
import Folders from "../Folders";

type Props = {
  show: boolean;
  onClose: () => void;
  onSubmit?: (data: LocalAsset[]) => void;
  item?: BrowseItem | null;
  onSuccess: (params: BrowseParams) => Promise<void>;
  directoryId: number;
};

const MoveFolder: FC<Props> = (props) => {
  const { show, onClose, item, onSuccess, directoryId } = props;
  const { getList, changeParent } = useBrowse();

  const [data, setData] = useState<BrowseItem[]>([]);
  const [search, setSearch] = useState("");
  const [selectedDestination, setSelectedDestination] =
    useState<BrowseItem | null>(null);
  const debounceSearch = useDebounce(search);

  useEffect(() => {
    if (show) {
      setSelectedDestination({id: directoryId, type: "directory"} as BrowseItem);
    }
  }, [directoryId, show]);

  useEffect(() => {
    if (debounceSearch.length >= 3 || !debounceSearch.length) {
      getList({
        keyword: debounceSearch,
        per_page: 50,
      })
        .then((response) => setData(response?.data || []))
        .catch((err) => console.log("Err", err));
    }
  }, [debounceSearch, getList]);

  const handleClose = () => {
    onClose();
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleSubmit = async () => {
    if (!selectedDestination) {
      onClose();
    }
    if (item && selectedDestination) {
      const success = await changeParent({
        item: item,
        parent: selectedDestination,
      });
      if (success.success) {
        alert('Berhasil memindahkan item');
        await onSuccess({ directory_id: directoryId });
      } else {
        alert(success.message);
      }
    }
    onClose();
  };

  return (
    <Modal
      show={show}
      title={"Pilih Direktori"}
      onClose={handleClose}
      customFooter={
        <div className="float-right flex gap-x-2">
          <Button.Cancel onButtonClick={handleCancel} />
          {selectedDestination && (
            <Button color="primary" onButtonClick={handleSubmit}>
              Pilih Direktori tujuan
            </Button>
          )}
        </div>
      }
    >
      <div className="bg-white px-4 py-[0.625rem] rounded-xl flex gap-x-2 items-center border w-full mb-4">
        <FontAwesomeIcon icon={icon({ name: "search" })} />
        <input
          className="w-full"
          placeholder="Search..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        {Boolean(search.length) && (
          <FontAwesomeIcon
            icon={icon({ name: "close" })}
            onClick={() => setSearch("")}
            className="cursor-pointer"
          />
        )}
      </div>
      <p className="text-sm font-bold">Direktori</p>
      <Folders
        directoryId={Number(0)}
        indent={1}
        initialData={data}
        onSelectDirectory={(item) => setSelectedDestination(item)}
        selectedDirectory={selectedDestination}
      />
    </Modal>
  );
};

export default memo(MoveFolder);
