import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputNumber } from "components";
import ToggleSwitch from "components/ToggleSwitch";
import { DatePicker } from "components/datepicker";
import { FC, memo, useEffect, useMemo, useState } from "react";
import { getFormatDate } from "utilities";

type Props = {
  data: Partial<TryoutPayload>;
  canEdit?: boolean;
  onSave?: (data: Partial<TryoutPayload>) => void;
};

const Config: FC<Props> = (props) => {
  const { canEdit = false, data, onSave } = props;
  const [currentData, setData] = useState(data);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    setData(data);
  }, [data]);

  const toggleEdit = () => setIsEdit(!isEdit);

  const participants = useMemo(() => {
    if (currentData.is_limited_participant) {
      return currentData.number_of_participants;
    }
    return "Tidak terbatas";
  }, [currentData.is_limited_participant, currentData.number_of_participants]);

  const registration = useMemo(() => {
    const start = getFormatDate(currentData?.registration_period_start || "");
    const end = getFormatDate(currentData?.registration_period_end || "");

    return `${start} - ${end}`;
  }, [
    currentData.registration_period_end,
    currentData.registration_period_start,
  ]);

  const implementation = useMemo(() => {
    const start = getFormatDate(currentData?.start_date || "");
    const end = getFormatDate(currentData?.end_date || "");

    return `${start} - ${end}`;
  }, [currentData.end_date, currentData.start_date]);

  const onCancel = () => {
    setData(data);
    toggleEdit();
  };

  const handleSave = () => {
    if (onSave && canEdit) {
      onSave(currentData);
    }
    toggleEdit();
  };

  return (
    <div className="bg-white rounded-xl">
      <div className="flex items-center justify-between px-5 py-4 border-b">
        <p className="font-bold">Konfigurasi</p>
        {canEdit &&
          (isEdit ? (
            <div className="flex items-center gap-x-2">
              <FontAwesomeIcon
                color="#F04438"
                icon={icon({ name: "close" })}
                className="cursor-pointer"
                onClick={onCancel}
              />
              <FontAwesomeIcon
                color="#17B26A"
                icon={icon({ name: "floppy-disk", style: "regular" })}
                className="cursor-pointer"
                onClick={handleSave}
              />
            </div>
          ) : (
            <FontAwesomeIcon
              color="#B5A300"
              icon={icon({ name: "edit" })}
              className="cursor-pointer"
              onClick={toggleEdit}
            />
          ))}
      </div>
      <div className="grid gap-y-3 p-5">
        <div className="flex items-center flex-wrap gap-x-1.5">
          <p className="w-40 text-gray-500">Jumlah peserta</p>
          {isEdit ? (
            <div className="mt-2.5 flex flex-col gap-y-1.5 w-full">
              <div className="flex items-center gap-x-5">
                <div className="flex items-center gap-x-3">
                  <input
                    type="radio"
                    name="participants"
                    id="unlimited"
                    className="radio checked:bg-primary checked:border-none flex"
                    checked={!currentData.is_limited_participant}
                    onChange={() =>
                      setData({
                        ...currentData,
                        is_limited_participant: false,
                        number_of_participants: null,
                      })
                    }
                  />
                  <label htmlFor="unlimited">Tidak terbatas</label>
                </div>
                <div className="flex items-center gap-x-3">
                  <input
                    type="radio"
                    name="participants"
                    id="limited"
                    className="radio checked:bg-primary checked:border-none flex"
                    checked={currentData.is_limited_participant}
                    onChange={() =>
                      setData({
                        ...currentData,
                        is_limited_participant: true,
                        number_of_participants: null,
                      })
                    }
                  />
                  <label htmlFor="limited">Dibatasi</label>
                </div>
              </div>
              {currentData.is_limited_participant && (
                <InputNumber
                  label=""
                  placeholder="Jumlah peserta"
                  options={{
                    numeral: true,
                    delimiter: ".",
                    numeralDecimalMark: ",",
                  }}
                  disabled={!currentData.is_limited_participant}
                  className="w-full border border-gray-300 px-3.5 py-2.5 rounded-lg"
                  value={
                    currentData.is_limited_participant
                      ? currentData.number_of_participants || ""
                      : ""
                  }
                  onChange={(e) =>
                    setData({
                      ...currentData,
                      number_of_participants: Number(e.target.rawValue),
                    })
                  }
                />
              )}
            </div>
          ) : (
            <p>{participants}</p>
          )}
        </div>
        <div className="flex items-center flex-wrap gap-x-1.5">
          <p className="w-40 text-gray-500">Periode pendaftaran</p>
          {isEdit ? (
            <div className="grid grid-flow-col gap-x-5">
              <DatePicker
                label="Periode mulai"
                value={currentData.registration_period_start || ""}
                onChange={(val) =>
                  setData({
                    ...currentData,
                    registration_period_start: val?.toString() || "",
                  })
                }
              />
              <DatePicker
                label="Periode akhir"
                value={currentData.registration_period_end || ""}
                onChange={(val) =>
                  setData({
                    ...currentData,
                    registration_period_end: val?.toString() || "",
                  })
                }
              />
            </div>
          ) : (
            <p>{registration}</p>
          )}
        </div>
        <div className="flex items-center flex-wrap gap-x-1.5">
          <p className="w-40 text-gray-500">Periode pelaksanaan</p>
          {isEdit ? (
            <div className="grid grid-flow-col gap-x-5">
              <DatePicker
                label="Periode mulai"
                value={currentData.start_date || ""}
                onChange={(val) =>
                  setData({
                    ...currentData,
                    start_date: val?.toString() || "",
                  })
                }
              />
              <DatePicker
                label="Periode akhir"
                value={currentData.end_date || ""}
                onChange={(val) =>
                  setData({
                    ...currentData,
                    end_date: val?.toString() || "",
                  })
                }
              />
            </div>
          ) : (
            <p>{implementation}</p>
          )}
        </div>
        <div className="flex items-center flex-wrap gap-x-1.5">
          <p className="w-40 text-gray-500">Jumlah soal</p>
          {isEdit ? (
            <InputNumber
              label=""
              options={{
                numeral: true,
                numeralDecimalMark: ",",
                delimiter: ".",
              }}
              placeholder="Tentukan jumlah soal"
              className="w-full border px-3.5 py-2.5 rounded-lg"
              value={currentData.question_each_student}
              onChange={(e) =>
                setData({
                  ...currentData,
                  question_each_student: Number(e.target.rawValue),
                })
              }
            />
          ) : (
            <p>{currentData.question_each_student}</p>
          )}
        </div>
        <div className="flex items-center flex-wrap gap-x-1.5">
          <p className="w-40 text-gray-500">Jumlah Percobaan</p>
          {isEdit ? (
            <InputNumber
              label=""
              options={{
                numeral: true,
                numeralDecimalMark: ",",
                delimiter: ".",
              }}
              placeholder="Tentukan jumlah soal"
              className="w-full border px-3.5 py-2.5 rounded-lg"
              value={currentData.max_attempt}
              onChange={(e) =>
                setData({
                  ...currentData,
                  max_attempt: Number(e.target.rawValue),
                })
              }
            />
          ) : (
            <p>{currentData.max_attempt}</p>
          )}
        </div>
        {isEdit ? (
          <ToggleSwitch
            label="Tampilkan pembahasan"
            isChecked={currentData.is_explanation_showed}
            onChange={(val) =>
              setData({
                ...currentData,
                is_explanation_showed: val,
              })
            }
          />
        ) : (
          <div className="flex items-center gap-x-1.5">
            <p className="w-40 text-gray-500">Pembahasan</p>
            <p>{currentData.is_explanation_showed ? "Ya" : "Tidak"}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(Config);
