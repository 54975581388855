import PointUsageList from "./fragment/PointUsageList";

const PointUsagePage = () => {
  return(
    <div className={'space-y-4'}>
      <PointUsageList/>
    </div>
  )
}

export default PointUsagePage
